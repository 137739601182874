export const useInventoryStore = defineStore('inventory', () => {
    const appConfig = useAppConfig();
    const dealersStore = useDealersStore();
    const filtersStore = useFiltersStore();
    const inventoryApi = new InventoryApi();

    const status = ref<Status>('initial');
    const all = shallowRef<Readonly<InventoryV2SearchVehicle>[]>([]);
    const uniqueNewCars = shallowRef<Readonly<InventoryGroupedModel>[]>([]);

    const carsWithVehiclePricing = computed(() => {
        return all.value.map((car) => {
            return {
                car,
                pricing: useVehiclePricing({
                    msrp: car.pricing.msrp,
                    isVehicleNew: car.type === 'N',
                    pageType: 'SRP',
                    isMsrpRequired: car.isMsrpRequired,
                    exclusiveDiscount: car.pricing.exclusiveDiscount,
                    markupsTotal: car.pricing.markupsTotal,
                    rebatesAppliedTotal: car.pricing.rebatesAppliedTotal,
                    rebatesEveryoneTotal: car.pricing.rebatesEveryoneTotal,
                    discountsTotal: car.pricing.discountsTotal,
                }),
            };
        });
    });

    const getNew = computed(() => {
        return carsWithVehiclePricing.value.filter(
            (item) => item.car.type === 'N'
        );
    });

    const getUsed = computed(() => {
        return carsWithVehiclePricing.value.filter(
            (item) => item.car.type === 'U'
        );
    });

    const getCpo = computed(() => {
        // Remove cpo cars if they are the wrong make
        if (appConfig.searchOptions.cpoFilterByMake) {
            return carsWithVehiclePricing.value.filter((item) => {
                return (
                    item.car.isCertified &&
                    dealersStore.currentDealer.makes.includes(item.car.make)
                );
            });
        }

        return carsWithVehiclePricing.value.filter(
            (item) => item.car.isCertified
        );
    });

    // The following computeds are the multiple layers of filtering and sort
    // for the SRP results
    const getVehiclesByType = computed(() => {
        if (filtersStore.getSelectedTypes.length === 0)
            return carsWithVehiclePricing.value;

        let vehicles: CarWithVehiclePricing[] = [];
        if (filtersStore.getSelectedTypes.includes('new')) {
            vehicles = vehicles.concat(getNew.value);
        }
        if (filtersStore.getSelectedTypes.includes('used')) {
            // Used list also contains cpo, do not combine them
            return vehicles.concat(getUsed.value);
        }
        if (filtersStore.getSelectedTypes.includes('cpo')) {
            vehicles = vehicles.concat(getCpo.value);
        }
        return vehicles;
    });
    const filteredCars = computed(() => {
        return getVehiclesByType.value.filter(
            (item) =>
                filterSelections(item.car) &&
                filterTags(item.car) &&
                filterPrice(item) &&
                filterMileage(item.car) &&
                filterKeywords(item.car)
        );
    });
    const sortedCars = computed(() => {
        const copy = [...filteredCars.value];
        return copy.sort((a, b) => {
            let first: number | string;
            let second: number | string;
            let compare: number;
            const { by: key, asc } =
                filtersStore.sort[filtersStore.currentType];
            const collator = new Intl.Collator('en', {
                sensitivity: 'base',
                ignorePunctuation: true,
                numeric: true,
            });

            if (key === 'price') {
                first = a.pricing.finalPriceValue.value;

                if (first === 0 && asc) {
                    first = 9999999;
                }

                second = b.pricing.finalPriceValue.value;

                if (second === 0 && asc) {
                    second = 9999999;
                }
            } else if (key === 'distance') {
                first =
                    dealersStore.valueDealerDistanceMap.get(
                        a.car.valueArkona
                    ) ?? 0;
                second =
                    dealersStore.valueDealerDistanceMap.get(
                        b.car.valueArkona
                    ) ?? 0;
            } else if (key === 'carGurusDealRatingSort') {
                first = a.car[key] || 0;
                second = b.car[key] || 0;
            } else {
                first = a.car[key];
                second = b.car[key];
            }
            if (typeof first === 'string' || typeof second === 'string') {
                compare = collator.compare(first.toString(), second.toString());
            } else {
                compare = first - second;
                if (compare === 0) {
                    compare = collator.compare(a.car.vin, b.car.vin);
                }
            }
            return asc ? compare : compare * -1;
        });
    });
    const groupedAndOrderedCars = computed(() => {
        // create groupings
        const onsiteWithPhoto: CarWithVehiclePricing[] = [];
        const onsiteNoPhoto: CarWithVehiclePricing[] = [];

        // create groupings with make
        const onsiteMakeWithPhoto: CarWithVehiclePricing[] = [];
        const onsiteMakeNoPhoto: CarWithVehiclePricing[] = [];

        // put cars into groups
        sortedCars.value.forEach((item) => {
            const hasPhoto =
                item.car.imageUrls[0] &&
                !item.car.imageUrls[0].includes('default');

            if (item.car.type === 'N') {
                if (hasPhoto) {
                    onsiteWithPhoto.push(item);
                } else {
                    onsiteNoPhoto.push(item);
                }
            } else if (hasPhoto) {
                // sort make to the top if in variables
                if (
                    appConfig.inventory.sortMakeToTop &&
                    item.car.make === appConfig.inventory.sortMakeToTop
                ) {
                    onsiteMakeWithPhoto.push(item);
                } else {
                    onsiteWithPhoto.push(item);
                }
            } else {
                // sort make to the top if in variables
                if (
                    appConfig.inventory.sortMakeToTop &&
                    item.car.make === appConfig.inventory.sortMakeToTop
                ) {
                    onsiteMakeNoPhoto.push(item);
                } else {
                    onsiteNoPhoto.push(item);
                }
            }
        });

        // return groups in desired order
        return [
            ...onsiteMakeWithPhoto,
            ...onsiteMakeNoPhoto,
            ...onsiteWithPhoto,
            ...onsiteNoPhoto,
        ];
    });
    const paginatedCars = computed(() => {
        return groupedAndOrderedCars.value.filter(
            (_car, index) => index < filtersStore.showingCount
        );
    });
    const carsWithCards = computed(() => {
        // Do nothing if there are no cards
        if (!appConfig.searchOptions.matchingCards.length) {
            return paginatedCars.value;
        }

        // Get seed positions
        const seedPositions = getSeedPositions(paginatedCars.value.length);

        // If no seed positions, return original array
        if (!seedPositions.length) {
            return paginatedCars.value;
        }

        // Copy original array before changing
        const seededArray: (CarWithVehiclePricing | { cardIndex: number })[] = [
            ...paginatedCars.value,
        ];

        // Create pool of available card indexes
        let availableCardPool = appConfig.searchOptions.matchingCards.map(
            (_, index) => index
        );

        // Add seeds into array
        seedPositions.forEach((pos) => {
            // Get random index from available pool
            const cardIndex = Math.floor(
                Math.random() * availableCardPool.length
            );

            seededArray.splice(pos, 0, {
                cardIndex: availableCardPool[cardIndex],
            });

            // Remove the card index from the available pool
            availableCardPool.splice(cardIndex, 1);

            // Reset the available pool if it is empty
            if (!availableCardPool.length) {
                availableCardPool = appConfig.searchOptions.matchingCards.map(
                    (_, index) => index
                );
            }
        });

        return seededArray;
    });

    const GET_ALL = async () => {
        if (status.value === 'loading') return;

        try {
            status.value = 'loading';

            all.value = await inventoryApi.getInventoryV2(
                appConfig.arkonas.fleet || appConfig.arkonas.site
            );

            filtersStore.GET_FILTER_OPTIONS(carsWithVehiclePricing.value);

            status.value = 'loaded';
        } catch (err) {
            console.error(err); // eslint-disable-line no-console
            status.value = 'error';
        }
    };

    const GET_UNIQUE_NEW_CARS = async () => {
        try {
            uniqueNewCars.value = await inventoryApi.getNewGroupedModels(
                appConfig.arkonas.fleet || appConfig.arkonas.site
            );
        } catch (err) {
            console.error(err); // eslint-disable-line no-console
        }
    };

    /**
     * Returns a key used in the counts map
     */
    const GET_COUNT_KEY = (makeModel: InventoryUniqueMakeModel) => {
        return `${makeModel.make}-${makeModel.model}`;
    };

    /**
     * Returns a map of make-model -> number.
     * This also counts submodels.
     */
    const counts = computed(() => {
        const map = new Map<string, number>();

        for (const makeModel of uniqueNewCars.value) {
            for (const item of getNew.value) {
                // Match on exact make and model
                const matchesMakeModel =
                    makeModel.make === item.car.make &&
                    makeModel.model === item.car.model;

                // Match on submodel
                const matchesSubModel = makeModel.subModels.includes(
                    item.car.model
                );

                if (matchesMakeModel || matchesSubModel) {
                    const key = GET_COUNT_KEY(makeModel);
                    map.set(key, (map.get(key) ?? 0) + 1);
                }
            }
        }

        return map;
    });

    /**
     * Returns the count of make+model combination
     */
    const GET_COUNT = (makeModel: InventoryUniqueMakeModel) => {
        const key = GET_COUNT_KEY(makeModel);
        const count = counts.value.get(key);
        return count ? `(${count})` : '(0)';
    };

    return {
        status,
        all,
        uniqueNewCars,
        carsWithVehiclePricing,
        getNew,
        getUsed,
        getCpo,
        getVehiclesByType,
        filteredCars,
        sortedCars,
        groupedAndOrderedCars,
        paginatedCars,
        carsWithCards,
        GET_ALL,
        GET_UNIQUE_NEW_CARS,
        counts,
        GET_COUNT,
    };
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useInventoryStore, import.meta.hot));
}
