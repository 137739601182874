function checkIfTypesMatch(
    car: InventoryV2SearchVehicle,
    valueToMatch: string | number
) {
    if (valueToMatch === 'new') return car.type === 'N';
    else if (valueToMatch === 'used') return car.type === 'U';
    else if (valueToMatch === 'cpo') return car.isCertified;
    return false;
}

function checkIfValuesMatch(
    valueToCheck: InventoryV2SearchVehicle[keyof InventoryV2SearchVehicle],
    valueToMatch: string | number
) {
    if (typeof valueToCheck !== 'string') {
        return valueToCheck === valueToMatch;
    }
    return valueToCheck.toUpperCase() === valueToMatch.toString().toUpperCase();
}

/**
 * Returns false if car does not match selections
 */
export function filterSelections(
    car: InventoryV2SearchVehicle,
    ignoredCategory?: string,
    ignoreTypes?: boolean
) {
    const { selections, groupedSelections } = useFiltersStore();

    if (!selections.length) return true;

    let matchesAll = true;
    for (const [option, selectionsArray] of Object.entries(groupedSelections)) {
        const hasMatches = selectionsArray.some((selection) => {
            if (isKeyOfObject(option, car)) {
                if (!ignoreTypes && option === 'type') {
                    return checkIfTypesMatch(car, selection.option);
                } else if (ignoredCategory && option === ignoredCategory) {
                    return true;
                } else {
                    return checkIfValuesMatch(car[option], selection.option);
                }
            } else {
                return false;
            }
        });
        if (!hasMatches) matchesAll = false;
    }

    return matchesAll;
}

/**
 * Returns false if car does not have matching tags
 */
export function filterTags(car: InventoryV2SearchVehicle) {
    const { hasSelectedTags, tags } = useFiltersStore();

    if (!hasSelectedTags) return true;

    return Object.entries(tags).some(([tag, tagDetails]) => {
        const tagIsSelected = tagDetails.isSelected;
        const carHasTag = isKeyOfObject(tag, car) && car[tag];

        // Early return if car is sold but `sold` wasn't selected in tag filters
        const isSoldSelected =
            tagDetails.label === 'Sold' && tagDetails.isSelected;
        if (car.isSold && !isSoldSelected) return false;

        return tagIsSelected && carHasTag;
    });
}

/**
 * Returns false if car price is out of range
 */
export function filterPrice(item: CarWithVehiclePricing) {
    const { categories, price } = useFiltersStore();

    if (categories.price[0] !== price[0]) {
        const min = parseInt(price[0].toString(), 10);

        if (item.pricing.finalPriceValue.value < min) return false;
    }

    if (categories.price[1] !== price[1]) {
        const max = parseInt(price[1].toString(), 10);

        if (item.pricing.finalPriceValue.value > max) return false;
    }

    return true;
}

/**
 * Returns false if car mileage is out of range
 */
export function filterMileage(car: InventoryV2SearchVehicle) {
    const { categories, mileage } = useFiltersStore();

    if (categories.mileage[0] !== mileage[0]) {
        const min = parseInt(mileage[0].toString(), 10);

        if (car.mileage < min) return false;
    }

    if (categories.mileage[1] !== mileage[1]) {
        const max = parseInt(mileage[1].toString(), 10);

        if (car.mileage > max) return false;
    }

    return true;
}

/**
 * Returns false if keywords do not match car
 */
export function filterKeywords(car: InventoryV2SearchVehicle) {
    const { keywords } = useFiltersStore();

    if (!keywords) return true;

    const splitKeywords = keywords.toString().trim().split(' ');
    return matchCarByKeywords(car, splitKeywords);
}
