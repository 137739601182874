import type { RouteLocationNormalizedLoaded } from '#vue-router';

export interface FiltersCategories {
    type: FrontendCarType[];
    year: number[];
    make: string[];
    model: string[];
    trim: string[];
    fuel: string[];
    body: string[];
    drive: string[];
    transmission: string[];
    price: [number, number];
    colorInt: string[];
    colorExt: string[];
    engine: string[];
    mileage: [number, number];
    location: string[];
    availability: string[];
}

interface FiltersSelection {
    category: keyof FiltersCategories;
    option: string | number;
}

export type FilterSortBy =
    | 'carGurusDealRatingSort'
    | 'price'
    | 'year'
    | 'make'
    | 'model'
    | 'trim'
    | 'distance';

export interface SortOption {
    label: string;
    by: FilterSortBy;
    asc: boolean;
}

export const useFiltersStore = defineStore('filters', () => {
    const appConfig = useAppConfig();

    const currentType = ref<FrontendCarType | 'all'>('new');
    const categories = ref<FiltersCategories>({
        type: ['new', 'used', 'cpo'], // Types are hard coded to these words on front-end only, backend uses 'N' and 'U
        year: [],
        make: [],
        model: [],
        trim: [],
        fuel: [],
        body: [],
        drive: [],
        transmission: [],
        price: [0, 0],
        colorInt: [],
        colorExt: [],
        engine: [],
        mileage: [0, 0],
        location: [],
        availability: [],
    });

    // The tags are displayed in this specific order (October 2024). Order is
    // the same for both filters store and TagIcons component.
    const tags = ref({
        isHeavilyDiscounted: { label: 'Value', isSelected: false },
        isPopular: { label: 'Hot', isSelected: false },
        isRare: { label: 'Rare', isSelected: false },
        isClassic: { label: 'Classic', isSelected: false }, // Used Only
        isSelect: { label: 'Select', isSelected: false }, // Used Only
        isCustom: { label: 'Customs', isSelected: false }, // New Only
        isInTransit: { label: 'In-Transit', isSelected: false }, // New Only
        isComingSoon: { label: 'Coming Soon', isSelected: false },
        isFleet: { label: 'Fleet', isSelected: false }, // New Only
        isPendingSold: { label: 'Pending', isSelected: false }, // Used Only
        isSold: { label: 'Sold', isSelected: false },
    });

    const selections = shallowRef<Readonly<FiltersSelection>[]>([]);
    const price = ref<[number, number]>([0, 1000000]);
    const mileage = ref<[number, number]>([0, 1000000]);
    const keywords = ref('');
    const showingCount = ref(12);

    // Create the available default sorts
    const defaultSort: SortOption = {
        label: 'Price: low to high',
        by: 'price',
        asc: true,
    };
    const defaultSortUsed: SortOption = appConfig.filtersOptions
        .defaultToPopularitySort
        ? { label: 'Popularity', by: 'carGurusDealRatingSort', asc: true }
        : defaultSort;
    const sort = ref<{
        new: SortOption;
        used: SortOption;
        cpo: SortOption;
        all: SortOption;
    }>({
        new: defaultSort,
        used: defaultSortUsed,
        cpo: defaultSortUsed,
        all: defaultSort, // For non-typed SRP
    });

    const sortOptions = ref<SortOption[]>([
        { label: 'Price: low to high', by: 'price', asc: true },
        { label: 'Price: high to low', by: 'price', asc: false },
        { label: 'Year: low to high', by: 'year', asc: true },
        { label: 'Year: high to low', by: 'year', asc: false },
        { label: 'Make: a to z', by: 'make', asc: true },
        { label: 'Make: z to a', by: 'make', asc: false },
        { label: 'Model: a to z', by: 'model', asc: true },
        { label: 'Model: z to a', by: 'model', asc: false },
        { label: 'Trim: a to z', by: 'trim', asc: true },
        { label: 'Trim: z to a', by: 'trim', asc: false },
        { label: 'Popularity', by: 'carGurusDealRatingSort', asc: true },
    ]);

    // For hub/value, add Distance sort option
    if (appConfig.hub.isValue) {
        sortOptions.value.push({
            label: 'Distance: low to high',
            by: 'distance',
            asc: true,
        });
    }

    const getValidQueryNames = computed(() => {
        return [
            ...Object.keys(categories.value),
            ...Object.keys(tags.value),
            'keywords',
            'minPrice',
            'maxPrice',
            'minMileage',
            'maxMileage',
        ];
    });

    const isTagKey = (query: string) => {
        return Object.keys(tags.value).includes(query);
    };

    const getSelectedTypes = computed(() => {
        return selections.value
            .filter((selection) => selection.category === 'type')
            .map((selection) => selection.option);
    });

    const groupedSelections = computed(() => {
        return selections.value.reduce(
            (acc, curr) => {
                if (acc[curr.category]) {
                    acc[curr.category].push(curr);
                } else {
                    acc[curr.category] = [curr];
                }
                return acc;
            },
            {} as Record<string, FiltersSelection[]>
        );
    });

    const hasSelectedTags = computed(() => {
        return Object.values(tags.value).some((tag) => tag.isSelected);
    });

    const SET_KEYWORDS = (newKeywords: string) => {
        keywords.value = newKeywords;
    };

    const SET_PRICE_SELECTIONS = (newPrice: [number, number]) => {
        price.value = [...newPrice];
    };

    const SET_MILEAGE_SELECTIONS = (newMileage: [number, number]) => {
        mileage.value = [...newMileage];
    };

    const ADD_QUERIES_TO_FILTERS = (route: RouteLocationNormalizedLoaded) => {
        const queries = Object.keys(route.query);

        // If there are no queries, make sure to clear all the filters
        if (!queries.length) CLEAR_FILTERS();

        const filterSelections: FiltersSelection[] = [];
        const validQueries = getValidQueryNames.value;
        queries.forEach((query) => {
            let queryValue = route.query[query];
            if (!queryValue) return;

            // Handles the queries that are not user inputted keywords
            if (validQueries.includes(query) && query !== 'keywords') {
                // Handle price and mileage
                if (query === 'minPrice') {
                    price.value[0] = parseInt(queryValue.toString());
                } else if (query === 'maxPrice') {
                    price.value[1] = parseInt(queryValue.toString());
                } else if (query === 'minMileage') {
                    mileage.value[0] = parseInt(queryValue.toString());
                } else if (query === 'maxMileage') {
                    mileage.value[1] = parseInt(queryValue.toString());
                }

                // Handle the rest of the possible queries (tags are handle in different function)
                else if (!isTagKey(query)) {
                    if (!Array.isArray(queryValue)) {
                        queryValue = [queryValue];
                    }

                    queryValue.forEach((value) => {
                        if (!value) return;

                        const option =
                            query === 'year'
                                ? parseInt(value)
                                : urlDecode(value);

                        filterSelections.push({
                            category: query as keyof FiltersCategories,
                            option: option,
                        });
                    });
                }
            }
        });

        // Add the keywords to the filters
        keywords.value = route.query.keywords
            ? route.query.keywords.toString()
            : '';

        // Add all other filters to the filters
        selections.value = filterSelections;

        // Update the tags
        const keys = Object.keys(tags.value);
        keys.forEach((key) => {
            if (isKeyOfObject(key, tags.value)) {
                const tagToChange = tags.value[key];
                if (tagToChange) tagToChange.isSelected = !!route.query[key];
            }
        });
    };

    const CLEAR_FILTERS = () => {
        // Clear the selections
        selections.value = [];

        // Clear keywords
        keywords.value = '';

        // Set prices to default values
        price.value = [categories.value.price[0], categories.value.price[1]];

        // Set mileage to default values
        mileage.value = [
            categories.value.mileage[0],
            categories.value.mileage[1],
        ];
    };

    const GET_FILTER_OPTIONS = (items: CarWithVehiclePricing[]) => {
        const keys = Object.keys(categories.value) as Array<
            keyof FiltersCategories
        >;
        const collator = new Intl.Collator('en', {
            sensitivity: 'base',
            ignorePunctuation: true,
            numeric: true,
        });

        keys.forEach((key) => {
            // Don't do anything for type
            if (key === 'type') {
                return;
            }

            // Handle price with type [number, number]
            if (key === 'price') {
                const limits: [number, number] = items.reduce(
                    (minmax, item) => {
                        if (item.pricing.finalPriceValue.value < minmax[0])
                            minmax[0] = item.pricing.finalPriceValue.value;
                        if (item.pricing.finalPriceValue.value > minmax[1])
                            minmax[1] = item.pricing.finalPriceValue.value;
                        return minmax;
                    },
                    [10000000, 0]
                );
                if (limits[0] < 0) limits[0] = 0;
                limits[0] = Math.floor(limits[0] / 5000) * 5000;
                limits[1] = Math.ceil(limits[1] / 5000) * 5000;

                categories.value.price = [...limits];
                price.value = [...limits];
            }

            // Handle mileage with type [number, number]
            else if (key === 'mileage') {
                const limits: [number, number] = items.reduce(
                    (minmax, item) => {
                        if (item.car.mileage < minmax[0]) {
                            minmax[0] = item.car.mileage;
                        }
                        if (item.car.mileage > minmax[1]) {
                            minmax[1] = item.car.mileage;
                        }
                        return minmax;
                    },
                    [10000000, 0]
                );
                if (limits[0] < 0) limits[0] = 0;
                limits[0] = Math.floor(limits[0] / 5000) * 5000;
                limits[1] = Math.ceil(limits[1] / 5000) * 5000;

                categories.value.mileage = [...limits];
                mileage.value = [...limits];
            }

            // Handle year with type number[]
            else if (key === 'year') {
                const list = items.reduce((acc, item) => {
                    if (!acc.includes(item.car[key])) {
                        acc.push(item.car[key]);
                    }
                    return acc;
                }, [] as number[]);

                list.sort();

                categories.value[key] = list;
            }

            // Handle remaining keys with type string[]
            else {
                const list = items.reduce((acc, item) => {
                    const value = item.car[key].trim();
                    if (value && !acc.includes(value)) {
                        acc.push(value);
                    }
                    return acc;
                }, [] as string[]);

                list.sort((a, b) => collator.compare(a, b));

                categories.value[key] = list;
            }
        });
    };

    return {
        currentType,
        categories,
        tags,
        selections,
        price,
        mileage,
        keywords,
        showingCount,
        sort,
        sortOptions,
        getValidQueryNames,
        isTagKey,
        getSelectedTypes,
        groupedSelections,
        hasSelectedTags,
        SET_KEYWORDS,
        SET_PRICE_SELECTIONS,
        SET_MILEAGE_SELECTIONS,
        ADD_QUERIES_TO_FILTERS,
        CLEAR_FILTERS,
        GET_FILTER_OPTIONS,
    };
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useFiltersStore, import.meta.hot));
}
